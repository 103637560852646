<template>
  <ListPage
    :gridData="filteredData"
    :gridConfig="gridConfig"
    :filters="filters"
  />
</template>

<script>
import { mapGetters } from "vuex";
import ListPage from "@/components/penal-retail-helpers/ListPage";
import { db } from "@/firebase/firebaseConfig";

let collectionName = "witnesses";

export default {
  components: {
    ListPage,
  },
  data() {
    let columns = [];

    [
      "code",
      "name",
      "lastname",
      "email",
      "mobile",
      "witnessType",
      "customer",
    ].forEach((a) => {
      columns.push({
        headerName: this.$t(`${collectionName}.columns.${a}`),
        filter: false,
        width: 100,
        field: a,
        ...(["witnessType", "customer"].includes(a)
          ? {
              valueGetter: (params) => {
                return params.data[a] ? params.data[a].alias : null;
              },
              filterValueGetter: (params) => {
                return params.data[a] ? params.data[a].alias : null;
              },
            }
          : {}),
      });
    });

    let filters = [];
    filters.push({
      size: "1/4",
      mobileSize: "1/2",
      extraClasses: "mb-5",
      search: true,
    });
    filters.push({
      size: "1/4",
      mobileSize: "1/2",
      select: true,
      placeholder: `${collectionName}.placeholders.customer`,
      label: "alias",
      clearable: true,
      resetOnOptionsChange: true,
      onChange: (e) => {
        this.updateFilters("customer", e);
      },
      options: () => this.allCustomers,
    });

    return {
      appliedFilters: {
        customer: null,
      },
      data: [],
      filters,
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
    };
  },
  computed: {
    ...mapGetters(["allCustomers"]),
    filteredData() {
      let filteredData = this.data;

      ["customer"].forEach((a) => {
        if (this.appliedFilters[a]) {
          filteredData = filteredData.filter(
            (x) => x[a].id === this.appliedFilters[a].id
          );
        }
      });

      return filteredData;
    },
  },
  created() {
    this.$bind(
      "data",
      db.collection(collectionName).where("deleted", "==", false),
      { wait: true }
    );
  },
  methods: {
    updateFilters(key, value) {
      if (this.appliedFilters[key] !== value) {
        this.appliedFilters[key] = value;
      }
    },
    onRowClicked(params) {
      this.$router
        .push(`/settings/${collectionName}/${params.data.id}`)
        .catch(() => {});
    },
  },
};
</script>
